import Flatpickr from 'stimulus-flatpickr';

// you can also import a translation file
import { French } from 'flatpickr/dist/l10n/fr.js';

// import a theme (could be in your main CSS entry too...)
import 'flatpickr/dist/themes/airbnb.css';

// create a new Stimulus controller by extending stimulus-flatpickr wrapper controller
// https://dev.to/adrienpoly/introducing-stimulus-flatpickr-wrapper--5c23
export default class extends Flatpickr {
  initialize() {
    // sets your language (you can also set some global setting for all time pickers)
    this.config = {
      locale: French,
      weekNumbers: true,
    };
  }

  // all flatpickr hooks are available as callbacks in your Stimulus controller
  change(selectedDates, dateStr, instance) {
    const dependentPickerEl = document.getElementById(this.element.dataset.dependentPickerId);
    const dependentPickerReminderEl = document.getElementById(
      this.element.dataset.dependentPickerReminderId,
    );
    if (dependentPickerEl) {
      const dependentPickerTime = this.addHours(selectedDates[0], 1);
      const dependentFlatPicker = dependentPickerEl._flatpickr;
      dependentFlatPicker.setDate(dependentPickerTime);
    }
    if (dependentPickerReminderEl) {
      if (dependentPickerReminderEl.value != '') {
        const dependentPickerReminderTime = this.addHours(selectedDates[0], 0);
        const dependentFlatPickerReminder = dependentPickerReminderEl._flatpickr;
        dependentFlatPickerReminder.setDate(dependentPickerReminderTime);
      }
    }
  }

  addHours(date, hours) {
    const newDate = new Date(date);
    newDate.setHours(newDate.getHours() + hours);
    return newDate;
  }
}
