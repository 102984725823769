// Load all the controllers within this directory and all subdirectories.
// Controller files must be named *_controller.js.

import { Application } from 'stimulus';
import { definitionsFromContext } from 'stimulus/webpack-helpers';

import { RevealController } from 'stimulus-reveal';
import { Autocomplete } from 'stimulus-autocomplete';

const application = Application.start();
const context = require.context('controllers', true, /_controller\.js$/);
const contextComponents = require.context('../../components', true, /_controller\.js$/);
application.load(definitionsFromContext(context).concat(definitionsFromContext(contextComponents)));
application.register('reveal', RevealController);
application.register('autocomplete', Autocomplete);
