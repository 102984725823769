import { Controller } from "stimulus"

export default class extends Controller {
    static targets = [ "survey", "title", "teams", "participants", "notifyParticipants", "recurringPlan" ]

    setTitleAndDefault() {
        if (this.hasTeamsTarget) {
            if (this.surveyTarget.selectedOptions[0].value === "") {
                this.teamsTarget.style.display = "block";
            } else {
                this.teamsTarget.style.display = "none";
            }
        }

        if (this.hasParticipantsTarget) {
            if (this.surveyTarget.selectedOptions[0].value === "") {
                this.participantsTarget.style.display = "block";
            } else {
                this.participantsTarget.style.display = "none";
            }
        }

        if (this.hasNotifyParticipantsTarget) {
            if (this.surveyTarget.selectedOptions[0].value === "") {
                this.notifyParticipantsTarget.style.display = "block";
            } else {
                this.notifyParticipantsTarget.style.display = "none";
            }
        }

        if (this.titleTarget.value == "") {
            this.titleTarget.value = this.surveyTarget.selectedOptions[0].text;
        }
    }

    showRecurringUntil() {
        if(this.recurringPlanTarget.selectedOptions[0].value !== "not_recurring") {
            $(".event-recurring-end").removeClass('d-none');
            $(".event-recurring-end").removeClass('invisible');
        } else {
            $(".event-recurring-end").addClass('d-none');
            $(".event-recurring-end").addClass('invisible');
        }
    }

    setAttendanceMode(event) {
        if(event.target.value == "online") {
            $(".event-address").addClass('d-none');
            $(".event-address").removeClass('d-block');
        } else {
            $(".event-address").addClass('d-block');
            $(".event-address").removeClass('d-none');
        }
    }
}
