import { Controller } from 'stimulus';

import Rails from '@rails/ujs';

export default class extends Controller {
  static targets = [
    'supplyForm',
    'supplyKind',
    'attachedDistributionVariantForm',
    'attachedDistributionVariantOption',
    'attachedDistributionVariantWeightValue',
    'attachedDistributionVariantWeightUnit',
    'existingDistributionVariantForm',
    'existingDistributionVariantSelect',
    'newDistributionVariantForm',
    'newDistributionVariantQuantity',
    'keepPurchaseVariantForm',
    'buttonForm',
    'supplyQuantityPerUnit',
    'newUnit',
    'newUnitWeight',
    'unitWeight',
    'totalQuantity',
    'totalWeight',
    'receptionModeForm',
  ];

  static values = {
    variantAttached: Boolean,
  };

  initialize() {
    if (this.supplyKindTarget.value == '') {
      this.setDefaultSupplyKindForm();
    } else {
      this.setCurrentSupplyKindForm(this.supplyKindTarget.value);
    }
  }

  handleDistributionVariantSelectChange(event) {
    const variantId = event.target.value;
    const basePath = event.target.dataset.basePath;
    const resourcePath = [basePath, variantId].join('/');
    this.fetchItemVariantAndUpdateDisplay(resourcePath);
  }

  fetchItemVariantAndUpdateDisplay(resource) {
    const self = this;
    Rails.ajax({
      type: 'get',
      dataType: 'json',
      url: resource,
      success: function (response) {
        self.setDistributionVariantWeight(response.weight_value, response.weight_unit);
        self.setSupplyQuantityPerUnit(response.quantity, response.item_unit.name);
      },
      error: function (response) {
        const messages = response.messages;
        if (messages) {
          alert(messages.join(', '));
        } else {
          console.dir(response);
        }
      },
    });
  }

  // FIXME: not used (for now?)
  updateVariantWeight() {
    const unitWeightValue = Number.parseFloat(
      this.totalWeightTarget.value / this.totalQuantityTarget.value,
    ).toFixed(2);
    this.unitWeightTargets.forEach((element, index) => {
      if (element.value == '') {
        element.value = unitWeightValue;
      }
    });
  }

  // FIXME: not used (for now?)
  updateTotalWeight() {
    console.dir(this.unitWeightTargets);
    if (Number.parseFloat(this.unitWeightTarget.value) > 0) {
      if (this.totalWeightTarget.value == '') {
        this.totalWeightTarget.value = Number.parseFloat(
          this.unitWeightTarget.value * this.totalQuantityTarget.value,
        ).toFixed(2);
      }
    }
  }

  toggleReceptionMode(event) {
    const target = event.target;
    if (target.value == 'partial') {
      this.receptionModeFormTarget.classList.remove('hidden');
    } else {
      this.receptionModeFormTarget.classList.add('hidden');
    }
  }

  handleAttachedOption(event) {
    const target = event.target;
    this.setSupplyQuantityPerUnit(target.dataset.variantQuantity, target.dataset.variantUnit);
    this.setVariantWeight(target.dataset.variantWeightValue, target.dataset.variantWeightUnit);
  }

  handleNewVariantUnitOption(event) {
    const target = event.target;
    this.setSupplyQuantityPerUnit(
      this.newDistributionVariantQuantityTarget.value,
      target.options[target.selectedIndex].text,
    );
  }

  handleNewVariantQuantity(event) {
    const target = event.target;
    this.setSupplyQuantityPerUnit(
      target.value,
      this.newUnitTarget.options[this.newUnitTarget.selectedIndex].text,
    );
  }

  setVariantWeight(weight, unit) {
    if (weight && unit) {
      // 1. update field for weight_value
      this.attachedDistributionVariantWeightValueTarget.value = weight;
      // 2. update select for weight_unit
    } else {
      this.attachedDistributionVariantWeightValueTarget.value = '';
    }
  }

  setDistributionVariantWeight(weight, unit) {
    if (weight && unit) {
      // 1. update field for weight_value
      this.unitWeightTarget.value = weight;
      // 2. update select for weight_unit
    } else {
      this.unitWeightTarget.value = '';
    }
  }

  setSupplyQuantityPerUnit(quantity, unit) {
    if (quantity && unit) {
      this.supplyQuantityPerUnitTarget.innerHTML = `x ${quantity} ${unit}`;
    } else {
      this.supplyQuantityPerUnitTarget.innerHTML = '';
    }
  }

  changeSupplyKindValue(event) {
    const target = event.target.value;
    const change_event = new Event('change');
    this.supplyKindTarget.value = target;
    this.supplyKindTarget.dispatchEvent(change_event);
  }

  // Change variant form based on radio selection
  changeSupplyKindForm(event) {
    const target = event.target.value;
    this.setCurrentSupplyKindForm(target);
  }

  setDefaultSupplyKindForm() {
    const defaulTarget = 'attached_distribution_variant';
    this.supplyKindTarget.value = defaulTarget;
    this.setCurrentSupplyKindForm(defaulTarget);
  }

  disableButtonForm(condition) {
    if (condition) {
      this.buttonFormTarget.setAttribute('disabled', true);
    } else {
      this.buttonFormTarget.removeAttribute('disabled');
    }
  }

  setCurrentSupplyKindForm(target) {
    if (target == 'attached_distribution_variant') {
      this.attachedDistributionVariantFormTarget.removeAttribute('hidden');
      this.existingDistributionVariantFormTarget.setAttribute('hidden', true);
      this.keepPurchaseVariantFormTarget.setAttribute('hidden', true);
      this.newDistributionVariantFormTarget.setAttribute('hidden', true);
      this.disableButtonForm(this.variantAttachedValue === false);
      // set quantity per unit text to the variant in the first radio button
      const defaultAttachedDistributionVariantOption =
        this.attachedDistributionVariantOptionTargets[0];
      if (defaultAttachedDistributionVariantOption) {
        this.setSupplyQuantityPerUnit(
          defaultAttachedDistributionVariantOption.dataset.variantQuantity,
          defaultAttachedDistributionVariantOption.dataset.variantUnit,
        );
        this.setVariantWeight(
          defaultAttachedDistributionVariantOption.dataset.variantWeightValue,
          defaultAttachedDistributionVariantOption.dataset.variantWeightUnit,
        );
        defaultAttachedDistributionVariantOption.checked = true;
        // this.totalQuantityTarget.value = defaultAttachedDistributionVariantOption.dataset.variantQuantity;
      } else {
        this.setSupplyQuantityPerUnit(null, null);
      }
    } else if (target == 'existing_distribution_variant') {
      this.attachedDistributionVariantFormTarget.setAttribute('hidden', true);
      this.existingDistributionVariantFormTarget.removeAttribute('hidden');
      this.keepPurchaseVariantFormTarget.setAttribute('hidden', true);
      this.newDistributionVariantFormTarget.setAttribute('hidden', true);
      this.disableButtonForm(false);
      // set quantity per unit text to "" beacause we need to wait for the selection of a variant by a user
      this.setSupplyQuantityPerUnit(null, null);
    } else if (target == 'new_distribution_variant') {
      this.attachedDistributionVariantFormTarget.setAttribute('hidden', true);
      this.existingDistributionVariantFormTarget.setAttribute('hidden', true);
      this.keepPurchaseVariantFormTarget.setAttribute('hidden', true);
      this.newDistributionVariantFormTarget.removeAttribute('hidden');
      this.disableButtonForm(false);
      // Reset new unit
      let variantUnit = this.supplyFormTarget.dataset.defaultVariantUnit;
      if (this.supplyFormTarget.dataset.variantSplittableUnitId != '') {
        this.newUnitTarget.value = this.supplyFormTarget.dataset.variantSplittableUnitId;
        variantUnit = this.supplyFormTarget.dataset.variantSplittableUnit;
        // set the total quantity
        const totalQuantity =
          this.supplyFormTarget.dataset.variantSplittableQuantity *
          this.supplyFormTarget.dataset.itemInventoryQuantity;
        this.totalQuantityTarget.value = totalQuantity;
        if (Number.parseFloat(totalQuantity) > 0) {
          this.newUnitWeightTarget.value = Number.parseFloat(
            this.supplyFormTarget.dataset.itemInventoryTotalWeight / totalQuantity,
          ).toFixed(2);
        }
      } else {
        this.newUnitTarget.value = this.supplyFormTarget.dataset.defaultVariantUnitId;
        // set the total quantity
        const totalQuantity = this.supplyFormTarget.dataset.itemInventoryQuantity;
        this.totalQuantityTarget.value = totalQuantity;
        if (Number.parseFloat(totalQuantity) > 0) {
          this.newUnitWeightTarget.value = Number.parseFloat(
            this.supplyFormTarget.dataset.itemInventoryTotalWeight / totalQuantity,
          ).toFixed(2);
        }
      }
      // set quantity per unit text to the quantity and not selected as default in the form to create a new variant
      this.setSupplyQuantityPerUnit(this.newDistributionVariantQuantityTarget.value, variantUnit);
    } else if (target == 'keep_purchase_variant') {
      this.attachedDistributionVariantFormTarget.setAttribute('hidden', true);
      this.existingDistributionVariantFormTarget.setAttribute('hidden', true);
      this.keepPurchaseVariantFormTarget.removeAttribute('hidden');
      this.newDistributionVariantFormTarget.setAttribute('hidden', true);
      this.disableButtonForm(false);
      // set quantity per unit text to the quantity of the purchased variant
      this.setSupplyQuantityPerUnit(
        this.supplyFormTarget.dataset.variantQuantity,
        this.supplyFormTarget.dataset.variantUnit,
      );
      this.totalQuantityTarget.value = this.supplyFormTarget.dataset.itemInventoryQuantity;
    }
  }
}
