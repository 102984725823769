import { Controller } from 'stimulus';
import jsQR from 'jsqr';

export default class extends Controller {
  static targets = ['canvas'];

  showVideo(event) {
    event.preventDefault();
    this.canvasTarget.classList.remove('hidden');
    this.canvasTarget.classList.add('block');
    console.log('Initialize Scanner');
    let _this = this;
    navigator.mediaDevices
      .getUserMedia({ video: { facingMode: 'environment' } })
      .then(function (stream) {
        _this.stream = stream;
        _this.video().srcObject = stream;
        _this.video().setAttribute('playsinline', true); // required to tell iOS safari we don't want fullscreen
        _this.video().play();
      });

    setInterval(function () {
      _this.tick();
    }, 50);
  }

  disconnect() {
    if (this.stream != undefined) {
      this.stream.getTracks().forEach(function (track) {
        track.stop();
      });
    }
  }

  accountId() {
    if (this._accountId == undefined) {
      this._accountId = document.getElementsByTagName('body')[0].dataset.accountId;
    }
    return this._accountId;
  }

  video() {
    if (this._video == undefined) {
      this._video = document.createElement('video');
    }
    return this._video;
  }

  canvas() {
    if (this._canvas == undefined) {
      this._canvas = this.canvasTarget.getContext('2d');
    }
    return this._canvas;
  }

  tick() {
    if (this.video().readyState === this.video().HAVE_ENOUGH_DATA) {
      this.canvas().drawImage(
        this.video(),
        0,
        0,
        this.canvasTarget.width,
        this.canvasTarget.height,
      );
      let imageData = this.canvas().getImageData(
        0,
        0,
        this.canvasTarget.width,
        this.canvasTarget.height,
      );
      let code = jsQR(imageData.data, imageData.width, imageData.height, {
        // inversionAttempts: 'dontInvert',
      });
      if (code) {
        this.canvasTarget.hidden = true;
        this.canvasTarget.classList.remove('block');
        this.canvasTarget.classList.add('hidden');
        this.disconnect();
        window.location.href = this.parseUrl(
          code.data + `&scanned_from_account_id=${this.accountId()}`,
        );
      }
    }
  }

  parseUrl(url) {
    console.dir(url)
    let newUrl = new URL(url);
    console.dir(newUrl)
    const currentUrl = new URL(window.location.href);
    console.dir(currentUrl)
    newUrl.hostname = currentUrl.hostname;
    console.log(newUrl.pathname)

    return newUrl.pathname + newUrl.search;
  }
}
