import { Controller } from 'stimulus';
import Rails from '@rails/ujs';

export default class extends Controller {
  static targets = [
    'component',
    'termField',
    'results',
    'searchForm',
    'advancedSearch',
    'advancedSearchTitle',
  ];
  timeout;
  pendingRequest;

  // AJAX search methods
  loading() {
    this.pendingRequest = true;
    setTimeout(() => {
      if (this.pendingRequest) {
        this.resultsTarget.innerHTML = `<div class="px-6 py-14 text-center sm:px-14">${I18n.searching}</div>`;
      }
    }, 500);
  }

  search() {
    this.closeAdvancedSearch();
    clearTimeout(this.timeout);
    this.timeout = setTimeout(() => {
      Rails.fire(this.searchFormTarget, 'submit');
    }, 500);
  }

  countNonEmptyAvancedSearchFields(el) {
    let count = 0;
    if (el) {
      count = this.countNonEmptyFields(el);
    }
    return count;
  }

  countNonEmptyFields(el) {
    const inputs = el.querySelectorAll('select');
    let count = 0;
    for (let i = 0; i < inputs.length; i++) {
      if (inputs[i].value != '') {
        count += 1;
      }
    }
    return count;
  }

  handleResults() {
    if (this.hasAdvancedSearchTitleTarget) {
      this.advancedSearchTitleTarget.textContent = `Filtres additionnels (${this.countNonEmptyAvancedSearchFields(
        this.advancedSearchTarget,
      )})`;
    }
    this.pendingRequest = false;
    const [, , xhr] = event.detail;
    this.resultsTarget.innerHTML = xhr.response;
    if (this.hasTermFieldTarget) {
      setTimeout(() => {
        this.termFieldTarget.blur();
      }, 500);
    }
  }

  // Display Methods
  // General Component
  toggle(event) {
    event.preventDefault();
    if (this.hasComponentTarget) {
      const element = this.componentTarget;
      if (element.classList.contains('block')) {
        // Hide
        element.classList.remove('block');
        element.classList.add('hidden');
      } else {
        // Show
        element.classList.remove('hidden');
        element.classList.add('block');
        // HACK: focus not working on hidden element just being displayed
        // There must be a better way :(
        if (this.hasTermFieldTarget) {
          setTimeout(() => {
            this.termFieldTarget.focus();
            this.termFieldTarget.select();
          }, 100);
        }
      }
    }
  }

  close(event) {
    event.preventDefault();
    if (this.hasComponentTarget) {
      const element = this.componentTarget;
      element.classList.remove('block');
      element.classList.add('hidden');
    }
  }

  // Advanced Search
  toggleAdvancedSearch(event) {
    event.preventDefault();
    if (this.hasAdvancedSearchTarget) {
      const element = this.advancedSearchTarget;
      if (element.classList.contains('hidden')) {
        // Hide
        element.classList.remove('hidden');
        element.classList.add('block');
      } else {
        // Show
        element.classList.remove('block');
        element.classList.add('hidden');
      }
    }
  }

  closeAdvancedSearch() {
    if (this.hasAdvancedSearchTarget) {
      const element = this.advancedSearchTarget;
      element.classList.remove('block');
      element.classList.add('hidden');
    }
  }
}
